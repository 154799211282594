<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список пройденных анкет</div>
    </div>

    <table class="table table-hover table-bordered fs-12 mt-2">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">ФИО</th>
        <th scope="col">Телефон</th>
        <th scope="col">Email</th>
        <th scope="col">День рождение</th>
        <th scope="col">Пол</th>
        <th scope="col">Регион</th>
        <th scope="col">Животные</th>
        <th scope="col">Дети</th>
        <th scope="col">Куда чаще всего заказываете?</th>
        <th scope="col">Как вы узнали о нас?</th>
        <th scope="col">Где вы ещё покупаете</th>
        <th scope="col">Любимые товары</th>
        <th scope="col">В какие месяцы вы уезжаете на отдых и отсутствуете в городе?</th>
        <th scope="col">Какие страны вы обычно посещаете?</th>
        <th scope="col">Предпочтения в питании</th>
        <th scope="col">Куда бы вы пошли в свой выходной</th>
        <th scope="col">Какими соц сетями вы больше всего</th>
        <th scope="col">Ваш ник в социальных сетях</th>
        <th scope="col">В каком магазине вы чаще закупаетесь</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in listQuestions" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ item.name }}</td>
        <td>{{ item.phone }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.birthday }}</td>
        <td>
          <template v-if="item.sex == 'MALE'">
            Мужской
          </template>
          <template v-else>
            Женский
          </template>
        </td>
        <td>
          <template v-if="item.region == 77">
            Москва
          </template>
          <template v-else>
            Санкт-Петербург
          </template>
        </td>
        <td>{{ animalTypes[index] }}</td>
        <td>{{ item.children }}</td>
        <td>
          <template v-if="item.point == 1">
            Частный дом
          </template>
          <template v-else-if="item.point == 2">
            Квартира
          </template>
          <template v-else>
            Офис
          </template>
        </td>
        <td>{{ howTypes[index] }}</td>
        <th>
          <div v-for="(item, index) in JSON.parse(item.other_stores)" :key="index">
            <template v-if="item.value.length > 0">
              <span>{{ item.title }}: </span>
              <div v-for="(val, index) in item.value" :key="index">
                <template v-if="val == 0">Онлайн</template>
                <template v-if="val == 1">Офлайн</template>
              </div>
            </template>
          </div>
        </th>
        <th>
          <template v-if="item.product_1">
            {{ item.product_1.title }} <br>
          </template>
          <template v-if="item.product_2">
            {{ item.product_2.title }} <br>
          </template>
          <template v-if="item.product_3">
            {{ item.product_3.title }} <br>
          </template>
        </th>
        <th>
          <div v-for="item in JSON.parse(item.months)" :key="item">
            <p v-if="item == 1">Январь</p>
            <p v-if="item == 2">Февраль</p>
            <p v-if="item == 3">Март</p>
            <p v-if="item == 4">Апрель</p>
            <p v-if="item == 5">Май</p>
            <p v-if="item == 6">Июнь</p>
            <p v-if="item == 7">Июль</p>
            <p v-if="item == 8">Август</p>
            <p v-if="item == 9">Сентябрь</p>
            <p v-if="item == 10">Октябрь</p>
            <p v-if="item == 11">Ноябрь</p>
            <p v-if="item == 12">Декабрь</p>
          </div>
        </th>
        <th>
          <template v-if="item.country_1">
            {{ item.country_1 }} <br>
          </template>
          <template v-if="item.country_2">
            {{ item.country_2 }} <br>
          </template>
          <template v-if="item.country_3">
            {{ item.country_3 }} <br>
          </template>
        </th>
        <th>
          <div v-for="item in JSON.parse(item.food_t)" :key="item">
            <p v-if="item == 1">Вегетарианец</p>
            <p v-if="item == 2">Веган</p>
            <p v-if="item == 3">Кошер</p>
            <p v-if="item == 4">Халяль</p>
            <p v-if="item == 5">Без глютена</p>
            <p v-if="item == 6">Без сахара</p>
            <p v-if="item == 7">Без лактозы</p>
            <p v-if="item == 8">Нет предпочтений</p>
          </div>
        </th>
        <th>
          <div v-for="item in JSON.parse(item.weekends)" :key="item">
            <p v-if="item == 1">Парк</p>
            <p v-if="item == 2">Кино</p>
            <p v-if="item == 3">Спортзал</p>
            <p v-if="item == 4">Шопинг</p>
            <p v-if="item == 5">Лежать на диване</p>
            <p v-if="item == 6">Всегда выходной</p>
            <p v-if="item == 7">Другое</p>
          </div>
        </th>
        <th>
          <div v-for="item in JSON.parse(item.socials)" :key="item">
            <p v-if="item == 1">Instagram</p>
            <p v-if="item == 2">Youtube</p>
            <p v-if="item == 3">VK</p>
            <p v-if="item == 4">Tik-Tok</p>
            <p v-if="item == 5">Яндекс Дзен</p>
          </div>
        </th>
        <th>{{ item.instagram }}</th>
        <th>
          <div v-for="(item, index) in JSON.parse(item.popular_stores)" :key="index">
            <template v-if="item.value !== false">
              <span>{{ item.title }}:
              <template v-if="item.value === 0">Часто</template>
              <template v-else-if="item.value === 1">Редко</template>
              <template v-else>-</template>
            </span>
            </template>
          </div>
        </th>
      </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-primary" @click="getMore">Загрузить ещё</a>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'list-users',
  data: function () {
    return {
      filter: {
        visible: 12,
      }
    }
  },
  computed: {
    ...mapGetters(['listQuestions']),
    animalTypes: function () {
      return this.listQuestions.map(function (item) {
        let types = JSON.parse(item.animal_types);
        let str = '';
        types.forEach((val) => {
          if (val == 1) {
            str += 'Птичка '
          } else if (val == 2) {
            str += 'Кошка '
          } else if (val == 3) {
            str += 'Собачка '
          } else if (val == 4) {
            str += 'Рыбки '
          } else if (val == 5) {
            str += 'Другое '
          } else if (val == 6) {
            str = 'Нет'
          }
        });
        str = str.trim();
        return str.replaceAll(' ', ',');
      });
    },
    howTypes: function () {
      return this.listQuestions.map(function (item) {
        let how = JSON.parse(item.how);
        let str = '';
        how.forEach((val) => {
          if (val == 1) {
            str += 'Блогеры Инстаграм '
          } else if (val == 2) {
            str += 'Прочие блогеры '
          } else if (val == 3) {
            str += 'Наш Инстаграм '
          } else if (val == 4) {
            str += 'В яндекс, гугл поисковике '
          } else if (val == 5) {
            str += 'Баннерная в Интернете '
          } else if (val == 6) {
            str += 'Посоветовал друг '
          } else if (val == 7) {
            str += 'Увидели на улице '
          } else if (val == 8) {
            str += 'ТВ, радио '
          } else if (val == 9) {
            str += 'Другое '
          }
        });
        str = str.trim();
        return str;
      });
    },
  },
  async mounted() {
    this.getListQuestion();
  },
  methods: {
    getMore: function () {
      this.filter.visible += 12;
      this.getListQuestion();
    },
    getListQuestion: function () {
      this.$store.dispatch('getUserQuestion', this.filter);
    },
  },
}
</script>
